<template>
  <section class="wa-full-height">

    <div class="columns is-centered is-gapless is-marginless mt2rem mb1rem is-centered">
      <div class="column is-6-desktop is-8-tablet is-12-mobile">
        <h1>agenda</h1>
        
      </div>
    </div>
    
    <AgendaComponent :period="agendaPeriod" :events="events" style="width: 80%;margin-left: 20%;"/>

    <!-- <Footer></Footer> -->
  </section>
</template>

<script>

// import axios from 'axios';
// import axios from '@/plugins/axios_api';
// import axios from '@/plugins/axios_auth';

// import Storage from '@/helpers/storage';
// import { COOKIE_REFRESH_TOKEN } from '@/config/app.js';
// import store from '@/store';
import AgendaComponent from "@/views/public/test/agenda_component.vue";

export default {
  name: "Agenda",
  
  components: {
    AgendaComponent
  },
    
  data() {
    return {
      agendaPeriod: {
        from: new Date('2025-01-01'), 
        till: new Date('2025-06-01')
      },
      events: [
        {
          name: 'unavailable',
          color: 'red',
          from: new Date('2025-01-08 10:00'),
          till: new Date('2025-01-09 18:00'),
          type: 'unavailable'
        },
        {
          name: 'unavailable',
          color: 'red',
          from: new Date('2025-01-01 10:00'),
          till: new Date('2025-01-15 18:00'),
          type: 'unavailable'
        },
        {
          name: 'work',
          color: 'green',
          from: new Date('2025-01-09 22:00'),
          till: new Date('2025-01-14 18:00'),
          type: 'unavailable'
        },
      ]
    }
  },
  
  
  mounted(){
    // this.agendaPeriod = {
    //   from: new Date(),
    //   till: new Date()
    // }
  },
  
  methods: {
            
    
    
  },
};
</script>

<style scoped>
</style>