<template>
  <section class="wa-full-height fullScreentest">
    <!-- <h1 class="">test</h1> -->

    <div class="AgandaWrapper">
      <b-field class="columns is-multiline" style="padding-top: 25px;">
            <div class="column is-1">
              <b-button class="button is-primary AgendaCloseBtn" @click="$emit('closeAgenda')">close</b-button>
            </div>
            <div class="column is-1">
              <b-field> <b-checkbox v-model="showTypeVerlof" @input="calcDays">Verlof</b-checkbox></b-field>
            </div>
            
            <div class="column is-1">
              <b-field> <b-checkbox v-model="showTypeUnavailable" @input="calcDays">Unavailable</b-checkbox></b-field>
            </div>
            <div class="column is-1">
              <b-field> <b-checkbox v-model="showTypeOpenforWork" @input="calcDays">OpenForWork</b-checkbox></b-field>
            </div>
            
            <div class="column is-1">
              <b-field> <b-checkbox v-model="showTypeShift" @input="calcDays">Shifts</b-checkbox></b-field>
            </div>
            <div class="column is-1">
              <b-field> <b-checkbox v-model="showUnavailabilityIssues" @input="calcDays">UnavailableIssues</b-checkbox></b-field>
            </div>
            <div class="column is-1">
              <b-field> <b-checkbox v-model="showTypeWorkElseWhere" @input="calcDays">WorkElseWhere</b-checkbox></b-field>
            </div>
            <div class="column is-1">
              <b-field> <b-checkbox v-model="showTypeBlocked" @input="calcDays">Blocked</b-checkbox></b-field>
            </div>
            <div class="column is-12">
              {{user.user_id}} - {{user.email}} - {{user.quotum}} -> {{user.assignedFiltered.length}}
            </div>
        </b-field>

      <div class="grid-container">
        <div v-for="(day, index) in days" v-bind:key="`day_${index}`" class="grid-item">
          <div :class="day.classes.join(` `)">
            <div class="dayLabel">{{ day.nameLabel}} {{ day.holidays.map(h => h.name).join(`, `)}}</div>

            <div v-for="(event, eIndex) in day.events" v-bind:key="`day_${index}_event_${eIndex}`" class="eventWrap">
              <div class="event" :style="`width:${event.widthP}%;margin-left:${event.offsetP}%;background-color:${event.bcolor};color:${event.color};`">
                {{ event.name}}
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>


  </section>
</template>

<script>

import myDates from '@/helpers/dates.js';
// import axios from '@/plugins/axios_api';
// import axios from '@/plugins/axios_auth';

// import Storage from '@/helpers/storage';
// import { COOKIE_REFRESH_TOKEN } from '@/config/app.js';
// import store from '@/store';

export default {
  name: "AgendaComponent",

  props: {
    period: {
      from: Object,
      till: Object,
    },
    events: [],
    holidays: [],
    user: {}

  },

  data() {
    return {
      from: this.period.from,
      till: this.period.till,
      days: [],
      showTypeVerlof: true,
      showTypeBlocked: true,
      showTypeUnavailable: true,
      showTypeOpenforWork: true,
      showTypeWorkElseWhere: true,
      showTypeShift: true,
      showUnavailabilityIssues: true,
      
      
      lastuser: null
    }
  },

  mounted(){
    this.calcDays()
    setInterval(() => {
      if (this.lastuser !== null && this.lastuser.user_id !== this.user.user_id) {
        console.info(`recaltulating! ${this.lastuser.user_id} vs ${this.user.user_id}`)
         this.calcDays()
      }
    }, 50)
  },

  methods: {
    calcDays(){      
      this.lastuser = this.user
      
      let loopDate = new Date(this.from)
      loopDate.setDate(loopDate.getDate() - loopDate.getDay() +1) // start always on monday

      // console.info(`start: ${myDates.DDMMYYYY(loopDate)}`)
      let days = []
      do {
        let endLoopDate = new Date(loopDate)
        endLoopDate.setDate(loopDate.getDate() +1)

        let day = {
          name: myDates.DDMM(loopDate),
          nameLabel: myDates.DDMM(loopDate),
          isWeekend: loopDate.getDay() === 0 || loopDate.getDay() === 6,
          start: new Date(loopDate),
          end: new Date(endLoopDate),
          classes: ['weekday'],
          events: this.getEventsWithOverlap(loopDate, endLoopDate),
          holidays: this.getDayHolidays(loopDate, endLoopDate)
        }



        if (day.isWeekend) {
          day.classes.push('weekendDay')
        }
        if (day.holidays.length > 0) {
          day.classes.push('holiday')
        }

        let unavailabiltyOverlap = this.getUnavailabilityOverlap(day)
        if (this.showUnavailabilityIssues && unavailabiltyOverlap.length > 0) {
          day.classes.push('unavailabilityOverlap')
        }

        days.push(day)
        loopDate.setDate(loopDate.getDate() + 1)
      } while (loopDate.getTime() < this.till.getTime() + 25*3600*1000)
      this.days = days
      // console.info(`this.days:`, this.days)
    },
    getUnavailabilityOverlap(day){
      // console.info(`day:`, day)
      if (day.events.length <=0) return []

      let unavailable = day.events.filter(e => e.type === 'unavailable')
      if (unavailable.length <=0) return []
      // console.info(`unavailable:`, unavailable)

      let overLapIssues = []
      for(let unavail of unavailable) {
        let shifts = day.events.filter(e => e.type === 'shift')
        if (shifts.length <= 0) continue
        // console.info(`shifts:`, shifts)
        let hasOverlap = shifts.filter(s => myDates.hasOverLap(s.from, s.till, unavail.from, unavail.till))
        overLapIssues = [...overLapIssues, ...hasOverlap]
        // console.info(`overLapIssues:`, overLapIssues)
      }
      
      return overLapIssues
    },
    getDayHolidays(dayStart, dayEnd){
      // console.info(`holidays:`, this.holidays)
      let holidayList = this.holidays.filter(e => myDates.hasOverLap(dayStart, dayEnd, e.start, e.end))
      if (holidayList.length <=0) return []
      holidayList = holidayList.sort((a,b) => {
        return a.from.getTime() - b.from.getTime()
      })
      // console.info(`holidayList:`, holidayList)
      return [...holidayList]
    },

    getEventsWithOverlap(dayStart, dayEnd){
      let overlapEvents = this.events.filter(e => myDates.hasOverLap(dayStart, dayEnd, e.from, e.till))
      if (overlapEvents.length <=0) return []
      // console.info(`start: ${myDates.DDMMYYYY_HHii(dayStart)}`)
      // console.info(`end: ${myDates.DDMMYYYY_HHii(dayEnd)}`)
      overlapEvents = overlapEvents.sort((a,b) => {
        return a.from.getTime() - b.from.getTime()
      })

      let dayDuration = (dayEnd.getTime() - dayStart.getTime())
      let list = []
      for(let overlapEvent of overlapEvents) {
        if (!this.showTypeVerlof && overlapEvent.type === 'verlof') continue
        if (!this.showTypeBlocked && overlapEvent.type === 'blocked') continue
        if (!this.showTypeUnavailable && overlapEvent.type === 'unavailable') continue
        if (!this.showTypeOpenforWork && overlapEvent.type === 'open_to_work') continue
        if (!this.showTypeShift && overlapEvent.type === 'shift') continue
        if (!this.showTypeWorkElseWhere && overlapEvent.type === 'werk') continue




        let timeStart = Math.max(overlapEvent.from.getTime(), dayStart.getTime()) - dayStart.getTime()
        // console.info(`timeStart:`, timeStart )
        let endStart = Math.min(overlapEvent.till.getTime(), dayEnd.getTime()) - dayStart.getTime()
        // console.info(`endStart:`, endStart )
        let e = {
          dayStart: new Date(dayStart),
          dayEnd: new Date(dayEnd),
          timeStart: timeStart,
          endStart: endStart,
          offsetP: this.toPercent(timeStart / dayDuration),
          widthP: this.toPercent((endStart - timeStart) / dayDuration),
          from: overlapEvent.from,
          till:  overlapEvent.till,
          name: overlapEvent.name,
          bcolor: overlapEvent.bcolor,
          color: overlapEvent.color,
          type: overlapEvent.type
        }

        list.push(e)
      }
      // console.info(`getEventsWithOverlap:`, list)
      return list
    },
    toPercent(val) {
      return Math.round(val * 100 * 100) / 100
    }


  },
};
</script>

<style>
  .fullScreentest{
    float:left;
    position: fixed;
    left:0;
    top:0;
    width: 100%;
    min-height: 100%;
    background-color: #FFF;
    z-index: 999
  }

  .AgandaWrapper{
    float:left;
    width: 100%;
    min-height: 100%;
  }

  .grid-container{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(10, 1fr);
    /* gap: 2px; */
    width: 100%;
    min-height: 100%;
    background-color: #f0f0f0;
    padding: 10px;

  }

  .grid-item {
    /* background-color: #3498db; */
    color: #383838;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    min-height: 40px;
    border: 1px solid #cacaca;
    /* font-size: 16px; */
    /* font-weight: bold; */
    /* border-radius: 5px; */
  }

  /* .grid-item-1{
    grid-area: header;
  }
  .grid-item-2{
    grid-area: sidebar;
  }
  .grid-item-3{
    grid-area: content;
  } */
  .grid-item .weekday{
    width: 100%;
    /* background-color: purple; */
  }

  .grid-item .dayLabel{
    float:left;
    width: 100%;
    text-align: center;
    font-size: 8px;
    margin:0;
    padding:0;
    /* background-color: green; */
    position: relative;
  }


  .grid-item .weekendDay{
    background-color: #FCFBF0;
  }
  .grid-item .holiday{
    background-color: #FBF19A;
  }
  .grid-item .unavailabilityOverlap{
    /* border: 2px solid red; */
    background-color: #B71C1C;
  }


  .grid-item .eventWrap{
    float: left;
    clear: both;
    width: 100%;
    /* background-color: orange; */
  }

  .grid-item .event{
    float:left;
    height: 16px;
    font-size: 10px;
    text-align: center;
    overflow: hidden;
  }

  .AgendaCloseBtn{
    float:left;
    position: absolute;
    top: 15px;
    right:15px;
  }

</style>